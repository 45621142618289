<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Add New Tickets</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="md:col-9 sm:col-12 comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <form ref="observer"  tag="form" @submit.prevent="submitForm()">
                                    <div>
                                        <table class="p-datatable-table w-full">
                                            <thead class="p-datatable-head">
                                                <tr>
                                                    <th><label for="requestby">Requestby</label></th>
                                                    <th><label for="client">Client</label></th>
                                                    <th><label for="priority">Priority</label></th>
                                                    <th><label for="request">Request</label></th>
                                                    <th><label for="estimate">Estimate</label></th>
                                                    <th><label for="actual">Actual</label></th>
                                                    <th><label for="authorize">Authorize</label></th>
                                                    <th><label for="delivered">Delivered</label></th>
                                                    <th><label for="starttime">Starttime</label></th>
                                                    <th><label for="stoptime">Stoptime</label></th>
                                                    <th><label for="done">Done</label></th>
                                                    <th><label for="amount">Amount</label></th>
                                                    <th><label for="paidref">Paidref</label></th>
                                                    <th><label for="owner">Owner</label></th>
                                                    <td style="width:30px"></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(formData, row) in arrFormData" :key="row" class="input-row">
                                                    <td id="ctrl-requestby-holder">
                                                        <InputText ref="ctrlrequestby" v-model.trim="formData.requestby"  label="Requestby" type="text" placeholder="Enter Requestby"      
                                                        class=" w-full" :class="getErrorClass('requestby', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('requestby', row)" class="p-error">{{ getFieldError('requestby', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-client-holder">
                                                        <InputText ref="ctrlclient" v-model.trim="formData.client"  label="Client" type="text" placeholder="Enter Client"      
                                                        class=" w-full" :class="getErrorClass('client', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('client', row)" class="p-error">{{ getFieldError('client', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-priority-holder">
                                                        <InputText ref="ctrlpriority" v-model.trim="formData.priority"  label="Priority" type="number" placeholder="Enter Priority"   step="any"    
                                                        class=" w-full" :class="getErrorClass('priority', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('priority', row)" class="p-error">{{ getFieldError('priority', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-request-holder">
                                                        <InputText ref="ctrlrequest" v-model.trim="formData.request"  label="Request" type="text" placeholder="Enter Request"      
                                                        class=" w-full" :class="getErrorClass('request', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('request', row)" class="p-error">{{ getFieldError('request', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-estimate-holder">
                                                        <InputText ref="ctrlestimate" v-model.trim="formData.estimate"  label="Estimate" type="number" placeholder="Enter Estimate"   step="any"    
                                                        class=" w-full" :class="getErrorClass('estimate', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('estimate', row)" class="p-error">{{ getFieldError('estimate', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-actual-holder">
                                                        <InputText ref="ctrlactual" v-model.trim="formData.actual"  label="Actual" type="number" placeholder="Enter Actual"   step="any"    
                                                        class=" w-full" :class="getErrorClass('actual', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('actual', row)" class="p-error">{{ getFieldError('actual', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-authorize-holder">
                                                        <InputText ref="ctrlauthorize" v-model.trim="formData.authorize"  label="Authorize" type="text" placeholder="Enter Authorize"      
                                                        class=" w-full" :class="getErrorClass('authorize', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('authorize', row)" class="p-error">{{ getFieldError('authorize', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-delivered-holder">
                                                        <InputText ref="ctrldelivered" v-model.trim="formData.delivered"  label="Delivered" type="text" placeholder="Enter Delivered"      
                                                        class=" w-full" :class="getErrorClass('delivered', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('delivered', row)" class="p-error">{{ getFieldError('delivered', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-starttime-holder">
                                                        <Calendar :showButtonBar="true" :showTime="true" dateFormat="yy-mm-dd" hourFormat="24" v-model="formData.starttime" :showIcon="true" class="w-full" :class="getErrorClass('starttime', row)"     mask="yy-MM-DD HH:mm"     />
                                                        <small v-if="isFieldValid('starttime', row)" class="p-error">{{ getFieldError('starttime', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-stoptime-holder">
                                                        <Calendar :showButtonBar="true" :showTime="true" dateFormat="yy-mm-dd" hourFormat="24" v-model="formData.stoptime" :showIcon="true" class="w-full" :class="getErrorClass('stoptime', row)"     mask="yy-MM-DD HH:mm"     />
                                                        <small v-if="isFieldValid('stoptime', row)" class="p-error">{{ getFieldError('stoptime', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-done-holder">
                                                        <InputText ref="ctrldone" v-model.trim="formData.done"  label="Done" type="text" placeholder="Enter Done"      
                                                        class=" w-full" :class="getErrorClass('done', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('done', row)" class="p-error">{{ getFieldError('done', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-amount-holder">
                                                        <InputText ref="ctrlamount" v-model.trim="formData.amount"  label="Amount" type="number" placeholder="Enter Amount"   step="0.1"    
                                                        class=" w-full" :class="getErrorClass('amount', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('amount', row)" class="p-error">{{ getFieldError('amount', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-paidref-holder">
                                                        <InputText ref="ctrlpaidref" v-model.trim="formData.paidref"  label="Paidref" type="text" placeholder="Enter Paidref"      
                                                        class=" w-full" :class="getErrorClass('paidref', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('paidref', row)" class="p-error">{{ getFieldError('paidref', row) }}</small> 
                                                    </td>
                                                    <td id="ctrl-owner-holder">
                                                        <InputText ref="ctrlowner" v-model.trim="formData.owner"  label="Owner" type="text" placeholder="Enter Owner"      
                                                        class=" w-full" :class="getErrorClass('owner', row)">
                                                        </InputText>
                                                        <small v-if="isFieldValid('owner', row)" class="p-error">{{ getFieldError('owner', row) }}</small> 
                                                    </td>
                                                    <th>
                                                    <Button class="p-button-text p-button-danger p-button-rounded p-button-sm" v-if="arrFormData.length > minRowCount" @click="removeFormRow(row)" icon="pi pi-times" />
                                                    </th>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <td colspan="100" class="text-right">
                                                    <Button v-if="arrFormData.length < maxRowCount" @click="addFormRow()" icon="pi pi-plus" class="p-button-text p-button-success p-button-rounded p-button-sm" />
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center q-my-md">
                                        <Button type="submit" label="Submit" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { helpers, required, numeric, } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useAddPage } from '@/composables/addpage.js';
	const props = defineProps({
		pageName : {
			type : String,
			default : 'tickets',
		},
		routeName : {
			type : String,
			default : 'ticketsadd',
		},
		apiPath : {
			type : String,
			default : 'tickets/add',
		},
		submitButtonLabel: {
			type: String,
			default: "Submit",
		},
		msgAfterSave: {
			type: String,
			default: "Record added successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
		maxRowCount: {
			type : Number,
			default: 10,
		},
		minRowCount: {
			type : Number,
			default: 1,
		},
	});
	const app = useApp();
	const formDefaultValues = {
		requestby: "", 
		client: "", 
		priority: "", 
		request: "", 
		estimate: "", 
		actual: "", 
		authorize: "", 
		delivered: "", 
		starttime: "", 
		stoptime: "", 
		done: "", 
		amount: "", 
		paidref: "", 
		owner: "FASTPORT", 
	}
	const arrFormData = reactive([{ ...formDefaultValues }]);
	function onFormSubmited(response) {
		app.flashMsg(props.msgAfterSave);
		arrFormData = [{ ...formDefaultValues }]; // reset form data
		if(props.redirect) app.navigateTo(`/tickets`);
	}
	//validation rules
	const rules = computed(() => {
		return {
			$each: helpers.forEach({
				requestby: {  },
			client: {  },
			priority: { numeric },
			request: {  },
			estimate: { numeric },
			actual: { numeric },
			authorize: {  },
			delivered: {  },
			starttime: {  },
			stoptime: {  },
			done: {  },
			amount: { numeric },
			paidref: {  },
			owner: { required }
			})
		}
	});
	const v$ = useVuelidate(rules, arrFormData); // form validation
	const page = useAddPage({ props, formData: arrFormData, v$, onFormSubmited });
	function addFormRow() {
		arrFormData.push({ ...formDefaultValues });
	}
	function removeFormRow (index){
		arrFormData.splice(index, 1);
	}
	//page state
	const {
		submitted, // form api submitted state - Boolean
		saving, // form api submiting state - Boolean
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page methods
	const {
		submitForm, // submit form data to api
		getErrorClass, // return error class if field is invalid- args(fieldname, index)
		getFieldError, //  get validation error message - args(fieldname, index)
		isFieldValid, // check if field is validated - args(fieldname)
		 // map api datasource  to Select options label-value
	} = page.methods;
	onMounted(()=>{
		const pageTitle = "Add New Tickets";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped>
</style>
